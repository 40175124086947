// This component is heavily inspired by the UI work here: https://ui.shadcn.com/blocks#dashboard-02
import { Menu } from "lucide-react";
import React from "react";

import { cn } from "../../lib/css";
import { Button } from "../button";

/*
 * Root/Parent
 */
export function Dashboard({
  className,
  ...props
}: React.ComponentProps<"div">) {
  return (
    <div
      className={cn(
        "grid min-h-screen w-full md:grid-cols-[220px_1fr] lg:grid-cols-[280px_1fr]",
        className
      )}
      {...props}
    />
  );
}

/*
 * Sidebar
 */
export function DashboardSidebar({
  className,
  ...props
}: React.ComponentProps<"div">) {
  return (
    <div
      className={cn("bg-surface-light hidden border-r md:block", className)}
      {...props}
    />
  );
}

export function DashboardSidebarContent({
  className,
  ...props
}: React.ComponentProps<"div">) {
  return (
    <div
      className={cn("flex h-full max-h-screen flex-col gap-2", className)}
      {...props}
    />
  );
}

export function DashboardSidebarHeader({
  className,
  ...props
}: React.ComponentProps<"div">) {
  return (
    <div
      className={cn(
        "flex h-14 items-center border-b px-4 lg:h-[60px] lg:px-6",
        className
      )}
      {...props}
    />
  );
}
export function DashboardSidebarNav({
  className,
  ...props
}: React.ComponentProps<"nav">) {
  return (
    <div className="flex-1">
      <nav
        className={cn(
          "grid items-start px-2 text-sm font-medium lg:px-4",
          className
        )}
        {...props}
      />
    </div>
  );
}

export function DashboardSidebarNavItem({
  className,
  isActive,
  ...props
}: React.ComponentProps<"a"> & { isActive?: boolean }) {
  return (
    <a
      className={cn(
        "text-txt-dimmed hover:text-txt-primary flex items-center gap-3 rounded-lg px-3 py-2 transition-all",
        isActive && "text-txt-primary bg-gray-200/40",
        className
      )}
      {...props}
    />
  );
}

export function DashboardSidebarFooter({
  className,
  ...props
}: React.ComponentProps<"div">) {
  return <div className={cn("mt-auto p-4", className)} {...props} />;
}

/*
 * Main Panel
 */
export function DashboardMainPanel({
  className,
  ...props
}: React.ComponentProps<"div">) {
  return <div className={cn("flex flex-col", className)} {...props} />;
}

export function DashboardMainPanelHeader({
  className,
  ...props
}: React.ComponentProps<"header">) {
  return (
    <header
      className={cn(
        "bg-muted/40 flex h-14 items-center gap-4 border-b px-4 lg:h-[60px] lg:px-6",
        className
      )}
      {...props}
    />
  );
}

export function DashboardMainPanelContent({
  className,
  ...props
}: React.ComponentProps<"main">) {
  return (
    <main
      className={cn(
        "flex flex-1 flex-col gap-4 p-4 lg:gap-6 lg:p-6",
        className
      )}
      {...props}
    />
  );
}

export function DashboardMainPanelContentHeader({
  className,
  ...props
}: React.ComponentProps<"div">) {
  return <div className={cn("flex items-center", className)} {...props}></div>;
}

export function DashboardMainPanelContentBody({
  className,
  ...props
}: React.ComponentProps<"div">) {
  return (
    <div
      className={cn(
        "flex flex-1 items-center justify-center rounded-lg border border-dashed shadow-sm",
        className
      )}
      {...props}
    />
  );
}

/*
 * Mobile Menu
 */
export function DashboardMobileMenuTrigger({
  className,
  ...props
}: React.ComponentProps<typeof Button>) {
  return (
    <Button
      variant="outline"
      size="icon"
      className={cn("shrink-0 md:hidden", className)}
      {...props}
    >
      <Menu className="h-5 w-5" />
      <span className="sr-only">Toggle navigation menu</span>
    </Button>
  );
}

export function DashboardMobileMenuNav({
  className,
  ...props
}: React.ComponentProps<"nav">) {
  return (
    <nav
      className={cn("grid gap-2 text-lg font-medium", className)}
      {...props}
    />
  );
}

export function DashboardMobileMenuNavItem({
  className,
  isActive,
  ...props
}: React.ComponentProps<"a"> & { isActive?: boolean }) {
  return (
    <a
      className={cn(
        "text-txt-dimmed hover:text-txt-primary mx-[-0.65rem] flex items-center gap-4 rounded-xl px-3 py-2",
        isActive && "text-txt-primary bg-gray-200/40",
        className
      )}
      {...props}
    />
  );
}

export function DashboardMobileMenuFooter({
  className,
  ...props
}: React.ComponentProps<"div">) {
  return <div className={cn("mt-auto", className)} {...props} />;
}
