import * as Sentry from "@sentry/react";
import { createFileRoute, redirect } from "@tanstack/react-router";

import { createAccount as createAccountApi } from "@/api";
import LoaderScreen from "@/routes/-components/loader-screen";
import { CreateAccountResponse } from "@/types";
import { AxiosError, AxiosResponse } from "axios";

// Users arrive at this route via a redirect from the `/authorize` route.
// They arrive here, authenticated via Auth0, looking to claim an offer.
// We create a new account for them, sign the necessary legal agreements,
// and redirect them to their application
export const Route = createFileRoute("/authorize/claim/")({
  loader: async (props) => {
    const { context, location } = props;

    const { user: auth0User } = context.auth;

    const applicationId =
      ((location.search as Record<string, unknown>)?.applicationId as string) ||
      undefined;

    if (!applicationId) {
      throw redirect({
        to: "/",
        search: {
          error: "login_failed",
        },
      });
    }

    if (!auth0User?.sub || !auth0User?.email) {
      Sentry.captureMessage("Invalid auth0 user credentials");
      throw redirect({
        to: "/",
      });
    }

    let createAccountRes:
      | AxiosResponse<CreateAccountResponse, AxiosError>
      | undefined;

    try {
      createAccountRes = await createAccountApi({
        apiClient: context.apiClient,
        payload: {
          auth0_id: auth0User.sub,
          user_email: auth0User.email,
          application_id: applicationId,
          signed_agreements: true,
        },
      });
    } catch (err) {
      Sentry.captureException(err);

      if (err instanceof AxiosError) {
        if (err?.response?.status === 412) {
          throw redirect({
            to: `/offers/$id/claimed`,
            params: {
              id: applicationId,
            },
          });
        } else {
          Sentry.captureException(err);

          throw redirect({
            to: "/offers/$id",
            params: {
              id: applicationId,
            },
            search: {
              error: "offer_claim_failed",
            },
          });
        }
      }
    }

    if (createAccountRes?.data) {
      throw redirect({
        to: `/applications/$applicationId/identity`,
        params: {
          applicationId: createAccountRes.data.application.id,
        },
      });
    }
  },
  component: Page,
});

function Page() {
  return <LoaderScreen />;
}
