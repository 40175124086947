import { createFileRoute, redirect } from "@tanstack/react-router";

import { underwriteApplication } from "@/api";
import LoadingScreen from "@/routes/-components/loader-screen";

export const Route = createFileRoute(
  "/applications/$applicationId/_applicationLayout/underwriting/review/"
)({
  loader: async ({ context, params }) => {
    const apiClient = context.apiClient;

    // Update underwriting status
    const { stage, risk_assessement_status } = await underwriteApplication({
      apiClient,
      params: { application_id: params.applicationId as string },
    });

    if (
      stage === "risk_assessment_approved" ||
      (!risk_assessement_status && stage === "underwriting_approved")
    ) {
      throw redirect({
        to: "/applications/$applicationId/terms",
        params: { applicationId: params.applicationId },
        replace: true,
      });
    }

    if (
      stage === "risk_assessment_denied" ||
      (!risk_assessement_status && stage === "underwriting_denied")
    ) {
      throw redirect({
        to: "/applications/$applicationId/underwriting/denied",
        params: { applicationId: params.applicationId },
        replace: true,
      });
    }
  },
  pendingComponent: LoadingScreen,
  component: Page,
});

export function Page() {
  return (
    <div className="flex h-screen items-center justify-center">
      <div>
        <h1 className="text-text-900 mb-2 text-2xl lg:text-3xl">
          Your application is currently under review.
        </h1>
        <p className="text-text-600 text-base lg:text-lg lg:leading-tight">
          Please check back regularly.
        </p>
      </div>
    </div>
  );
}
