import { createFileRoute, redirect, useNavigate } from "@tanstack/react-router";
import { ArrowRightIcon, PencilIcon, BadgeCheckIcon } from "lucide-react";
import { ReactNode, useMemo, useState } from "react";

import Agreements from "@/routes/-components/agreements";
import { Button } from "@prime/ui/src/button";
import { Slider } from "@prime/ui/src/slider";

import XeroContainer from "../-components/Container";
import XeroAuthPng from "../-components/xero-auth.png";

export const Route = createFileRoute("/xero/welcome/")({
  component: Page,
  beforeLoad: async ({ context }) => {
    const { isAuthenticated } = context.auth;

    if (!isAuthenticated) {
      throw redirect({
        to: "/xero/auth",
      });
    }
  },
  loader: async () => ({}),
});

function OptionToggle({
  options = [3, 6, 9],
  onChange = () => {},
  value = options[0],
}: {
  options?: number[];
  value: number;
  onChange: (value: number) => void;
}) {
  return (
    <div className="bg-txt-inverted-dimmed box-border flex h-12 shrink-0 flex-row items-start justify-start self-stretch overflow-hidden rounded-md p-[3px] text-center text-sm">
      {options.map((term: number) => {
        const isSelected: boolean = term === value;
        const className: string = isSelected
          ? "cursor-pointer bg-surface-banner text-txt-inverted border-border-dark flex flex-1 flex-row items-center justify-center self-stretch rounded-lg border-[1px] border-solid px-3.5 py-0 shadow-[0px_2px_5px_rgba(0,_0,_0,_0.05)]"
          : "cursor-pointer flex flex-1 flex-row items-center justify-center self-stretch px-3.5 py-0";
        return (
          <div
            key={`term-${term}`}
            className={className}
            onClick={() => onChange(term)}
          >
            <div className="relative leading-[150%]">
              <span>{`${term} `}</span>
              <span className="text-xs">MONTHS</span>
            </div>
          </div>
        );
      })}
    </div>
  );
}

function StepContainer({
  children,
  currentIndex,
}: {
  children: ReactNode[];
  currentIndex: number;
}) {
  return (
    <div
      className={`flex max-w-full flex-1 flex-col overflow-hidden bg-[url(/assets/hero-background-transparent.png)] bg-cover bg-[top] bg-no-repeat`}
    >
      <div
        className={`flex flex-1 overflow-hidden`}
        style={{
          width: `${100 * children.length}%`,
        }}
      >
        <div
          className="flex flex-1 transition-transform duration-500 ease-in-out"
          style={{
            transform: `translateX(-${(currentIndex * 100) / children.length}%)`,
          }}
        >
          {children.map((child: ReactNode, index: number) => {
            return (
              <div
                key={index}
                className="flex flex-1 items-center justify-center p-12 text-center"
              >
                {child}
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}

function Page() {
  const navigate = useNavigate();
  const {
    is_prequalified = true,
    offer_id = "32XJ",
    offer_expiration = "2024-09-01T05:00:00.000Z",
    min_funding = 5000,
    max_funding = 50000,
    preferred_funding = 35000,
    terms = [3, 6, 9],
    preferred_term = 3,
    business_name = "SMALL BUSINESS LLC",
  } = {}; // get from somewhere

  const [funding, setFunding] = useState(preferred_funding);
  const [length, setLength] = useState(preferred_term);
  const [step, setStep] = useState(0);

  const progress = useMemo(() => (((step + 1) / 4) * 100).toFixed(0), [step]);

  const getXeroUserPermission = () => {
    window?.localStorage?.setItem?.(
      "requested_loan_amount",
      funding.toString()
    );
    window?.localStorage?.setItem?.("requested_term_length", length.toString());
    navigate({ to: "/xero/permission-request" });
  };

  return (
    <XeroContainer progress={progress}>
      <StepContainer currentIndex={step}>
        <div className="flex h-[520px] w-full max-w-[840px] flex-row items-center justify-start rounded-xl text-center shadow-[0px_0px_48px_rgba(0,_0,_0,_0.05)]">
          <div className="bg-gray box-border flex flex-1 flex-col items-start justify-start gap-[45px] self-stretch p-6 [backdrop-filter:blur(40px)]">
            {is_prequalified ? (
              <div className="bg-surface-inset flex flex-row items-start justify-start overflow-hidden rounded-sm text-[10px] font-medium">
                <div className="text-txt-inverted bg-surface-banner flex flex-row items-center justify-center rounded-bl-sm rounded-br-none rounded-tl-sm rounded-tr-none px-2 py-1.5">
                  <div className="relative leading-[150%]">
                    OFFER {offer_id}
                  </div>
                </div>
                <div className="rounded-10xs text-txt-tertiary flex flex-row items-center justify-center overflow-hidden px-2 py-1.5">
                  <div className="relative uppercase leading-[150%]">
                    Expires{" "}
                    {new Date(offer_expiration)
                      .toLocaleDateString("en-US", {
                        year: "numeric",
                        month: "long",
                        day: "numeric",
                      })
                      .toUpperCase()}
                  </div>
                </div>
              </div>
            ) : null}

            <div className="text-29xl text-mediumslateblue flex flex-1 flex-col items-start justify-start gap-[24px] self-stretch text-left">
              <div className="relative flex flex-col items-start justify-between self-stretch">
                <p className="text-btn font-medium">{business_name}</p>
                {is_prequalified ? (
                  <p className="text-txt-primary my-3 text-[42px] leading-[125%]">
                    You’re pre-qualified for up to{" "}
                    <span
                      onClick={() => setFunding(max_funding)}
                      className="text-btn bg-surface-light-branded cursor-pointer rounded-md px-3"
                    >
                      ${max_funding.toLocaleString("en-US")}
                    </span>{" "}
                    in funding!
                  </p>
                ) : (
                  <p className="text-txt-primary my-3 text-[42px] leading-[125%]">
                    Apply for a loan in minutes using your Xero account.
                  </p>
                )}
                <div className="text-txt-primary relative z-[3] self-stretch text-base leading-[150%]">
                  Apply for a loan instantly using only your Xero account. Skip
                  the paperwork and reviews–just connect your account to see
                  approved offers.
                </div>
                <a
                  href="#"
                  className="text-btn mt-6 text-base [text-decoration:underline]"
                >
                  Learn More
                </a>
              </div>
            </div>
          </div>
          <div className="bg-whitesmoke-200 border-darkgray box-border flex flex-1 flex-col items-start justify-center self-stretch border-l-[1px] border-solid text-left text-sm [backdrop-filter:blur(24px)]">
            <div className="border-gainsboro flex flex-col items-start justify-start self-stretch overflow-hidden rounded-b-none rounded-t-xl border-b-[1px] border-solid px-6 py-4">
              <div className="text-icon flex flex-row items-center justify-start self-stretch font-medium">
                {is_prequalified ? (
                  <div className="flex flex-row items-center justify-start gap-[8px]">
                    <BadgeCheckIcon size={21} />
                    <p>PRE-QUALIFIED OFFER</p>
                  </div>
                ) : (
                  <p>CUSTOMIZE YOUR PLAN</p>
                )}
              </div>
            </div>
            <div className="text-txt-secondary flex flex-1 flex-col items-start justify-between self-stretch overflow-hidden rounded-b-xl rounded-t-none p-6 text-base">
              <div className="flex flex-col items-start justify-start gap-[24px] self-stretch">
                <div className="flex flex-col items-start justify-start gap-[12px] self-stretch">
                  <div className="flex flex-col items-start justify-center gap-[12px] self-stretch rounded-lg">
                    <div className="relative leading-[150%]">
                      How much funding do you need?
                    </div>
                    <div className="bg-txt-inverted border-border-dark box-border flex h-[65px] flex-row items-center justify-between self-stretch rounded-md border-[1px] border-solid px-3 py-0 text-[24px] text-black shadow-[0px_2px_5px_rgba(0,_0,_0,_0.05)]">
                      <div className="flex h-10 flex-row items-center justify-start gap-[3px]">
                        <div className="relative opacity-[0.5]">$</div>
                        <div className="relative text-[36px]">
                          {funding.toLocaleString("en-US")}
                        </div>
                      </div>
                      <div className="bg-surface-inset flex h-10 w-10 flex-row items-center justify-center rounded-md opacity-[0.7]">
                        <PencilIcon size={16} className="text-btn" />
                      </div>
                    </div>
                  </div>
                  <div className="text-txt-dimmed flex flex-col items-start justify-start gap-3 self-stretch text-center text-xs">
                    <Slider
                      max={max_funding}
                      step={500}
                      min={min_funding}
                      value={[funding]}
                      onValueChange={([value]) => setFunding(value)}
                    />
                    <div className="flex flex-row items-start justify-between self-stretch text-sm">
                      <div
                        className="relative cursor-pointer leading-[150%]"
                        onClick={() => setFunding(min_funding)}
                      >
                        ${min_funding.toLocaleString("en-US")}
                      </div>
                      <div
                        className="relative cursor-pointer leading-[150%]"
                        onClick={() => setFunding(max_funding)}
                      >
                        ${max_funding.toLocaleString("en-US")}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="border-lightgray relative box-border h-px self-stretch border-t-[1px] border-solid" />
                <div className="flex flex-col items-start justify-center gap-[12px] self-stretch">
                  <div className="relative self-stretch leading-[150%]">
                    How long do you want to repay?
                  </div>
                  <OptionToggle
                    onChange={setLength}
                    value={length}
                    options={terms}
                  />
                </div>
              </div>
              <Button className="w-full" onClick={() => setStep(1)}>
                <div className="relative font-medium leading-[24px]">
                  {is_prequalified ? "Claim Your Offer" : "Apply with Xero"}
                </div>
                <ArrowRightIcon size={24} />
              </Button>
            </div>
          </div>
        </div>
        {/** Terms */}
        <div className="flex w-full max-w-[512px] flex-col items-center gap-6 p-6">
          <div className="text-start">
            <p className="text-txt-primary text-2xl font-semibold leading-[125%]">
              Before we get started
            </p>
            <div className="text-txt-tertiary z-[3] self-stretch text-base leading-[150%]">
              Please review and agree to our policies and disclosures that
              dictate how we’ll be working with you.
            </div>
          </div>

          <div className="rounded-xl p-3 shadow-[0px_0px_48px_rgba(0,_0,_0,_0.05)]">
            <Agreements />
          </div>
          <Button className="w-full" onClick={() => setStep(2)}>
            <div className="relative font-medium leading-[24px]">Continue</div>
            <ArrowRightIcon size={24} />
          </Button>
        </div>
        <div className="flex h-[520px] w-full max-w-[840px] flex-row items-center justify-start rounded-xl text-center shadow-[0px_0px_48px_rgba(0,_0,_0,_0.05)]">
          <div className="bg-gray box-border flex flex-1 flex-col items-start justify-start gap-[45px] self-stretch p-6 [backdrop-filter:blur(40px)]">
            <div className="text-29xl text-mediumslateblue flex flex-1 flex-col items-start justify-start gap-[24px] self-stretch text-left">
              <div className="relative flex flex-col items-start justify-between self-stretch">
                <p className="text-btn font-medium">{business_name}</p>

                <p className="text-txt-primary my-3 text-xl leading-[125%]">
                  Prime needs permission to access your Xero account
                </p>
                <div className="text-txt-primary relative z-[3] mb-3 self-stretch text-base leading-[150%]">
                  You Xero account data will be used to provide you with
                  financing options, without any need to submit financial
                  statements.
                </div>
                <ol className="text-txt-secondary list-decimal pl-4 text-base font-medium leading-[150%]">
                  <li>Select the organization you want to apply with.</li>
                  <li>Click “Allow Access”</li>
                </ol>

                <a
                  href="#"
                  className="text-btn mt-6 text-base [text-decoration:underline]"
                >
                  Learn More
                </a>
              </div>
            </div>
          </div>
          <div className="bg-whitesmoke-200 border-darkgray box-border flex flex-1 flex-col items-start justify-center self-stretch border-l-[1px] border-solid text-left text-sm [backdrop-filter:blur(24px)]">
            <div className="text-txt-secondary flex flex-1 flex-col items-center justify-between self-stretch overflow-hidden rounded-b-xl rounded-t-none p-6 text-base">
              <img src={XeroAuthPng} className="max-h-[75%] p-6" />
              <Button className="w-full" onClick={getXeroUserPermission}>
                <div className="relative font-medium leading-[24px]">
                  Continue to Xero
                </div>
                <ArrowRightIcon size={24} />
              </Button>
            </div>
          </div>
        </div>
      </StepContainer>
    </XeroContainer>
  );
}
