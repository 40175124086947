import CreditPull from "@/routes/_docs/docs/-content/credit-pull";
import ESign from "@/routes/_docs/docs/-content/e-sign";
import PrivacyPolicy from "@/routes/_docs/docs/-content/privacy-policy";
import TermsOfService from "@/routes/_docs/docs/-content/terms-of-service";
import { ScrollArea } from "@prime/ui/src/scroll-area";

function Agreements() {
  return (
    <>
      <ScrollArea className="h-64 w-full overflow-y-auto rounded-xl border-2 border-[#DEE1E8] bg-white py-4 pl-4 pr-6">
        <div className="flex flex-col gap-3">
          <TermsOfService />
          <PrivacyPolicy />
          <ESign />
          <CreditPull />
        </div>
      </ScrollArea>
      <p className="mt-3 text-sm font-light">
        By clicking &rdquo;Continue&ldquo;, you agree to the Terms of Service,
        Privacy Policy, E-Sign Agreement, and Credit Pull Consent displayed
        above.
      </p>
    </>
  );
}

export default Agreements;
