import * as z from "zod";
import { dateIsValid } from "@prime/ui/lib/utils";

export const beneficialOwnerSchema = z
  .object({
    first_name: z.string().min(1, { message: "First name is required" }),
    last_name: z.string().min(1, { message: "Last name is required" }),
    phone: z
      .string()
      .refine((value) => value.replace(/\D/g, "").length === 10, {
        message: "Please enter a valid phone number",
      }),
    ownership_percentage: z.coerce
      .number()
      .gte(25, "Ownership percentage must be at least 25%")
      .lte(100, "Ownership percentage should not exceed 100%"),
    address_search: z
      .string()
      .min(1, { message: "Please select an address from the dropdown" }),
    _google_address_line_1: z.string(),
    _google_address_line_2: z.string(),
    _google_address_city: z.string(),
    _google_address_state: z.string(),
    _google_address_postal_code: z.string(),
    _google_address_country_code: z.string(),
    date_of_birth: z.string().refine(
      (value) => {
        const validDate = dateIsValid(value);
        return (
          !!validDate &&
          validDate < new Date() &&
          validDate >= new Date(1900, 0, 1)
        );
      },
      { message: "Date of birth is required" }
    ),
    ssn: z.string().refine((value) => value.replace(/\D/g, "").length === 9, {
      message: "Social security number is required",
    }),
    email: z.string().email({ message: "Please enter a valid email address" }),
    is_signer: z.enum(["true", "false"]),
    role: z.enum(["ceo", "manager", "accountant", "vice_president"]),
    type: z.enum(["owner", "employee"]),
  })
  .refine(
    (data) => {
      return (
        Boolean(data._google_address_line_1) &&
        Boolean(data._google_address_city) &&
        Boolean(data._google_address_state) &&
        Boolean(data._google_address_postal_code) &&
        Boolean(data._google_address_country_code)
      );
    },
    {
      message: "Please select an address from the dropdown",
      path: ["address_search"],
    }
  );

export type BeneficialOwner = z.infer<typeof beneficialOwnerSchema>;
