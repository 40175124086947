import {
  Link,
  Outlet,
  createFileRoute,
  defer,
  redirect,
} from "@tanstack/react-router";
import {
  FileCheckIcon,
  FilePenLineIcon,
  LandmarkIcon,
  SquareUserIcon,
  UsersIcon,
} from "lucide-react";
import { useEffect, useState } from "react";

import { getApplication, getApplicationTerms } from "@/api";
import PrimeHeaderLogoSvg from "@/routes/-components/prime-header-logo.svg";
import { Terms } from "@/types";
import { getRouteByApplicationStage } from "@/utils";
import { Button } from "@prime/ui/src/button";
import { DashboardMobileMenuTrigger } from "@prime/ui/src/dashboard";
import {
  Portal,
  PortalAside,
  PortalAsideNavigation,
  PortalAsideNavigationItem,
  PortalAsideNavigationItemState,
  PortalContent,
  PortalHeader,
  PortalMain,
} from "@prime/ui/src/portal";
import { Sheet, SheetContent, SheetTrigger } from "@prime/ui/src/sheet";
import { Skeleton } from "@prime/ui/src/skeleton";

export const Route = createFileRoute(
  "/applications/$applicationId/_applicationLayout"
)({
  beforeLoad: async ({ context, params }) => {
    const { auth, apiClient } = context;
    const { isAuthenticated } = auth;

    if (!isAuthenticated) {
      throw redirect({
        to: "/",
        search: {
          error: "authentication_required",
        },
      });
    }

    // Fetch the application (using the $applicationId URL param)
    const application = await getApplication({
      apiClient,
      params: { id: params.applicationId },
    });

    const terms = getApplicationTerms({
      apiClient,
      params: { id: application.id },
    });

    const {
      to: redirectTo,
      params: redirectParams,
      _pathname: redirectPathname,
    } = getRouteByApplicationStage(application);

    const currentRoute = location.pathname;

    // If the current route is not the redirect route, redirect to the correct route
    if (currentRoute !== redirectPathname) {
      throw redirect({
        to: redirectTo,
        params: redirectParams,
        replace: true,
      });
    } else {
      // Expose the application object to any child pages via context (e.g. Route.useRouteContext() or context in beforeLoad/loader functions)
      return { application, terms: defer(terms) };
    }
  },
  component: Layout,
  pendingComponent: PendingLayout,
  errorComponent: ErrorLayout,
});

function Layout() {
  const context = Route.useRouteContext();
  const { auth, application, terms } = context || {};
  const { user, logout } = auth;
  const [termsData, setTermsData] = useState<Terms>();

  useEffect(() => {
    terms.then(({ terms: t }) => setTermsData(t?.[0]));
  }, [terms, setTermsData]);

  if (!user) return null;

  const truncatedUserEmail = user.email!.split("@")[0];

  const truncatedUserEmailDisplay =
    truncatedUserEmail.length > 15
      ? `${truncatedUserEmail.slice(0, 15)}...`
      : truncatedUserEmail;

  const getIdentityNavigationState = (): PortalAsideNavigationItemState => {
    switch (application.stage) {
      case "offer_claimed":
      case "offer_generated":
        return "active";
      default:
        return "complete";
    }
  };

  const getOwnershipNavigationState = (): PortalAsideNavigationItemState => {
    switch (application.stage) {
      case "identification_completed":
        return "active";
      case "offer_claimed":
      case "offer_generated":
        return "incomplete";
      default:
        return "complete";
    }
  };

  const getConnectBankNavigationState = (): PortalAsideNavigationItemState => {
    switch (application.stage) {
      case "verification_approved":
      case "connect_bank_started":
      case "connect_bank_completed":
      case "payment_provider_borrower_setup_completed":
        return "active";
      case "underwriting_approved":
      case "underwriting_review":
      case "underwriting_denied":
      case "risk_assessment_approved":
      case "risk_assessment_review":
      case "risk_assessment_denied":
      case "terms_accepted":
      case "agreements_review_completed":
      case "execute_loan_completed":
      case "execute_loan_started":
      case "payment_provider_borrower_bank_account_setup_completed":
        return "complete";
      default:
        return "incomplete";
    }
  };

  const getTermsNavigationState = (): PortalAsideNavigationItemState => {
    switch (application.stage) {
      case "underwriting_approved":
      case "payment_provider_borrower_bank_account_setup_completed":
        return "active";
      case "terms_accepted":
      case "agreements_review_completed":
      case "execute_loan_completed":
      case "execute_loan_started":
        return "complete";
      default:
        return "incomplete";
    }
  };

  const getSignAgreementsNavigationState =
    (): PortalAsideNavigationItemState => {
      switch (application.stage) {
        case "terms_accepted":
          return "active";
        default:
          return "incomplete";
      }
    };

  const identityNavigationState = getIdentityNavigationState();
  const ownershipNavigationState = getOwnershipNavigationState();
  const connectBankNavigationState = getConnectBankNavigationState();
  const termsNavigationState = getTermsNavigationState();
  const signAgreementsNavigationState = getSignAgreementsNavigationState();

  let header = <span className="h-6"></span>;

  if (identityNavigationState === "active") {
    header = (
      <span className="flex items-center gap-2">
        <SquareUserIcon className="h-6 w-6" />
        Verify Identity
      </span>
    );
  } else if (connectBankNavigationState === "active") {
    header = (
      <span className="flex items-center gap-2">
        <LandmarkIcon className="h-6 w-6" />
        Connect Bank
      </span>
    );
  } else if (ownershipNavigationState === "active") {
    header = (
      <span className="flex items-center gap-2">
        <UsersIcon className="h-6 w-6" />
        Ownership
      </span>
    );
  } else if (termsNavigationState === "active") {
    header = (
      <span className="flex items-center gap-2">
        <FileCheckIcon className="h-6 w-6" />
        Accept Terms
      </span>
    );
  } else if (signAgreementsNavigationState === "active") {
    header = (
      <span className="flex items-center gap-2">
        <FilePenLineIcon className="h-6 w-6" />
        Sign Agreements
      </span>
    );
  }

  function SidebarContent() {
    return (
      <>
        <div>
          <div>
            <PrimeHeaderLogoSvg className="h-8" color="#ffffff4C" />
            <p className="mt-6 text-xl font-medium text-white">
              {application.applicant_business_name}
            </p>
            <p className="mt-6 text-lg font-medium tracking-wider text-white">
              Term Loan
            </p>
            {termsData ? (
              <p className="text-txt-inverted mt-1 text-xs font-medium uppercase tracking-wider opacity-[0.7]">
                ${Math.floor(Number(termsData.loan_amount)).toLocaleString()} •{" "}
                {Math.floor(
                  (termsData.total_number_of_payments *
                    termsData.remittance_frequency) /
                    30
                )}{" "}
                MONTHS
              </p>
            ) : null}
          </div>
          <PortalAsideNavigation className="mt-6">
            <PortalAsideNavigationItem step="verify" state={"complete"} />

            <PortalAsideNavigationItem
              step="identity"
              state={identityNavigationState}
            />

            <PortalAsideNavigationItem
              step="ownership"
              state={ownershipNavigationState}
            />

            <PortalAsideNavigationItem
              step="connect-bank"
              state={connectBankNavigationState}
            />

            <PortalAsideNavigationItem
              step="accept-terms"
              state={termsNavigationState}
            />

            <PortalAsideNavigationItem
              step="sign"
              state={signAgreementsNavigationState}
            />
          </PortalAsideNavigation>
        </div>
        <footer className="flex flex-col gap-6">
          <div className="flex items-center justify-between rounded-xl bg-white/10 p-3">
            <p className="text-xs font-extralight">
              {truncatedUserEmailDisplay}
            </p>
            <Button
              size="sm"
              variant="ghost"
              onClick={() =>
                logout({ logoutParams: { returnTo: window.location.origin } })
              }
            >
              Logout
            </Button>
          </div>
          <ul className="flex flex-wrap items-center justify-center gap-4 text-center text-xs font-extralight">
            <li>
              <a href="mailto:support@primeft.com">Contact Support</a>
            </li>
            <li>
              <Link to="/docs/privacy-policy">Privacy Policy</Link>
            </li>
            <li>
              <Link to="/docs/terms-of-service">Terms of Service</Link>
            </li>
            <li>
              <Link to="/docs/e-sign">E-Sign</Link>
            </li>
          </ul>
        </footer>
      </>
    );
  }

  return (
    <Portal>
      <PortalAside>
        <SidebarContent />
      </PortalAside>
      <PortalMain className="bg-surface-card flex flex-col overflow-hidden rounded-xl">
        <PortalHeader className="flex flex-row">
          {header}
          <Sheet>
            <SheetTrigger asChild>
              <DashboardMobileMenuTrigger className="ml-auto" />
            </SheetTrigger>
            <SheetContent
              side="right"
              className="text-text-sidebar bg-surface-banner flex flex-col"
            >
              <SidebarContent />
            </SheetContent>
          </Sheet>
        </PortalHeader>
        <PortalContent className="m-auto flex max-w-xl flex-1 flex-col gap-6 overflow-auto p-6">
          <Outlet />
        </PortalContent>
      </PortalMain>
    </Portal>
  );
}

function ErrorLayout() {
  return (
    <div className="m-auto flex max-w-lg flex-col gap-6 p-6">
      <div className="rounded-xl border border-red-500 p-8">
        <h1 className="text-readonly text-2xl font-extralight">
          We&rsquo;re sorry, but there seems to be an error in your application.
          We are working to fix it.
        </h1>
      </div>
    </div>
  );
}

function PendingLayout() {
  return (
    <Portal>
      <PortalAside>
        <PortalAsideNavigation>
          <Skeleton className="h-6 w-full" />
          <Skeleton className="h-6 w-full" />
          <Skeleton className="h-6 w-full" />
          <Skeleton className="h-6 w-full" />
          <Skeleton className="h-6 w-full" />
        </PortalAsideNavigation>
      </PortalAside>
      <PortalMain>
        <PortalHeader>
          <Skeleton className="h-3 w-full" />
        </PortalHeader>
        <PortalContent>
          <Skeleton className="h-20 w-full" />
          <Skeleton className="mt-10 h-40 w-full" />
        </PortalContent>
      </PortalMain>
    </Portal>
  );
}
