import { useEffect, useState } from "react";
import { UseFormReturn, useForm } from "react-hook-form";

import { zodResolver } from "@hookform/resolvers/zod";
import { maskDate, maskPhone, maskSSN } from "@prime/ui/lib/masks";
import { convertPlaceAddressComponentsToAddress } from "@prime/ui/lib/utils";
import { AddressInput } from "@prime/ui/src/address-input";
import { Button } from "@prime/ui/src/button";
import { Dialog, DialogContent, DialogTrigger } from "@prime/ui/src/dialog";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormMessage,
} from "@prime/ui/src/form";
import { Input } from "@prime/ui/src/input";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@prime/ui/src/select";

import { type BeneficialOwner, beneficialOwnerSchema } from "../-schemas";

function AddBeneficialOwnerTrigger({
  handleAddBeneficialOwner,
}: {
  handleAddBeneficialOwner: (values: BeneficialOwner) => void;
}) {
  const form = useForm<BeneficialOwner>({
    resolver: zodResolver(beneficialOwnerSchema),
    defaultValues: {
      first_name: "",
      last_name: "",
      ownership_percentage: 25,
      address_search: "",
      date_of_birth: "",
      phone: "",
      email: "",
      ssn: "",
      is_signer: "false",
      role: "manager",
      type: "employee",
    },
  });

  const handleAddressSuggestionSelect = (
    address: google.maps.GeocoderAddressComponent[],
    suggestion: google.maps.places.AutocompletePrediction
  ) => {
    setAddressFormFields(address, suggestion, form);
  };

  const onSubmit = async (values: BeneficialOwner) => {
    handleAddBeneficialOwner(values);
    setOpen(false);
  };

  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (form.formState.isSubmitSuccessful) {
      form.reset();
    }
  }, [form, form.formState.isSubmitSuccessful]);

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogTrigger asChild>
        <Button variant={"outline"}>Add</Button>
      </DialogTrigger>
      <DialogContent>
        <p className="text-text-readonly text-2xl">Add Business Owner</p>
        <Form {...form}>
          <form
            className="flex flex-col gap-6"
            onSubmit={form.handleSubmit(onSubmit)}
          >
            <FormField
              control={form.control}
              name="first_name"
              render={({ field }) => (
                <FormItem>
                  <FormControl>
                    <Input placeholder="First Name" {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="last_name"
              render={({ field }) => (
                <FormItem>
                  <FormControl>
                    <Input placeholder="Last Name" {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <div className="grid grid-cols-6 items-end gap-4">
              <FormField
                control={form.control}
                name="is_signer"
                render={({ field }) => (
                  <FormItem className="col-span-3">
                    <Select
                      onValueChange={field.onChange}
                      defaultValue={field.value}
                    >
                      <FormControl>
                        <SelectTrigger placeholder="Signer">
                          <SelectValue />
                        </SelectTrigger>
                      </FormControl>
                      <SelectContent>
                        <SelectItem value="true">Yes</SelectItem>
                        <SelectItem value="false">No</SelectItem>
                      </SelectContent>
                    </Select>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="ownership_percentage"
                render={({ field }) => (
                  <FormItem className="col-span-3">
                    <FormControl>
                      <Input
                        type="number"
                        placeholder="Ownership Percentage"
                        {...field}
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
            </div>

            <FormField
              control={form.control}
              name="address_search"
              render={({ field }) => {
                return (
                  <FormItem className="col-span-6">
                    <FormControl>
                      <AddressInput
                        googlePlacesApiKey={
                          import.meta.env.VITE_APPLY_GOOGLE_PLACES_API_KEY
                        }
                        placeholder="Home Address"
                        onSuggestionSelect={handleAddressSuggestionSelect}
                        {...field}
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                );
              }}
            />
            <FormField
              control={form.control}
              name="date_of_birth"
              render={({ field }) => {
                return (
                  <FormItem>
                    <FormControl>
                      <Input
                        placeholder="Date of Birth"
                        valueModifier={maskDate}
                        {...field}
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                );
              }}
            />

            {/* Phone number */}
            <div className="grid grid-cols-6 gap-4">
              <FormField
                control={form.control}
                name="phone"
                render={({ field }) => {
                  return (
                    <FormItem className="col-span-6">
                      <FormControl>
                        <Input
                          placeholder="Phone Number"
                          valueModifier={maskPhone}
                          {...field}
                        />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  );
                }}
              />
            </div>

            {/* Email */}
            <div className="grid grid-cols-6 gap-4">
              <FormField
                control={form.control}
                name="email"
                render={({ field }) => {
                  return (
                    <FormItem className="col-span-6">
                      <FormControl>
                        <Input
                          type="email"
                          placeholder="Personal Email"
                          {...field}
                        />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  );
                }}
              />
            </div>

            <FormField
              control={form.control}
              name="ssn"
              render={({ field }) => {
                return (
                  <FormItem>
                    <FormControl>
                      <Input
                        placeholder="Social Security Number"
                        type="password"
                        valueModifier={maskSSN}
                        {...field}
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                );
              }}
            />

            <div className="grid grid-cols-6 gap-4">
              {/* Business Role */}
              <FormField
                control={form.control}
                name="role"
                render={({ field }) => (
                  <FormItem className="col-span-3">
                    <Select
                      onValueChange={field.onChange}
                      defaultValue={field.value}
                    >
                      <FormControl>
                        <SelectTrigger placeholder="Business Role">
                          <SelectValue />
                        </SelectTrigger>
                      </FormControl>
                      <SelectContent>
                        <SelectItem value="ceo">CEO</SelectItem>
                        <SelectItem value="manager">Manager</SelectItem>
                        <SelectItem value="accountant">Accountant</SelectItem>
                        <SelectItem value="vice_president">
                          Vice President
                        </SelectItem>
                      </SelectContent>
                    </Select>
                    <FormMessage />
                  </FormItem>
                )}
              />

              {/* Business Type */}
              <FormField
                control={form.control}
                name="type"
                render={({ field }) => (
                  <FormItem className="col-span-3">
                    <Select
                      onValueChange={field.onChange}
                      defaultValue={field.value}
                    >
                      <FormControl>
                        <SelectTrigger placeholder="Employment Type">
                          <SelectValue />
                        </SelectTrigger>
                      </FormControl>
                      <SelectContent>
                        <SelectItem value="owner">Owner</SelectItem>
                        <SelectItem value="employee">Employee</SelectItem>
                      </SelectContent>
                    </Select>
                    <FormMessage />
                  </FormItem>
                )}
              />
            </div>

            <Button>Add Owner</Button>
          </form>
        </Form>
      </DialogContent>
    </Dialog>
  );
}

function UpdateBeneficialOwnerTrigger({
  beneficialOwner,
  handleUpdateBeneficialOwner,
  handleDeleteBeneficialOwner,
}: {
  beneficialOwner: BeneficialOwner;
  handleUpdateBeneficialOwner: (values: BeneficialOwner) => void;
  handleDeleteBeneficialOwner: () => void;
}) {
  const form = useForm<BeneficialOwner>({
    resolver: zodResolver(beneficialOwnerSchema),
    defaultValues: beneficialOwner,
  });

  const onSubmit = async (values: BeneficialOwner) => {
    handleUpdateBeneficialOwner(values);
    setOpen(false);
  };

  const handleAddressSuggestionSelect = (
    address: google.maps.GeocoderAddressComponent[],
    suggestion: google.maps.places.AutocompletePrediction
  ) => {
    setAddressFormFields(address, suggestion, form);
  };

  const [open, setOpen] = useState(false);

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogTrigger asChild>
        <Button variant={"outline"}>Edit</Button>
      </DialogTrigger>
      <DialogContent>
        <p className="text-text-readonly text-2xl">Edit Business Owner</p>
        <Form {...form}>
          <form
            className="flex flex-col gap-6"
            onSubmit={form.handleSubmit(onSubmit)}
          >
            <FormField
              control={form.control}
              name="first_name"
              render={({ field }) => (
                <FormItem>
                  <FormControl>
                    <Input placeholder="First Name" {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="last_name"
              render={({ field }) => (
                <FormItem>
                  <FormControl>
                    <Input placeholder="Last Name" {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />

            <div className="grid grid-cols-6 items-end gap-4">
              <FormField
                control={form.control}
                name="is_signer"
                render={({ field }) => (
                  <FormItem className="col-span-3">
                    <Select
                      onValueChange={field.onChange}
                      defaultValue={field.value}
                    >
                      <FormControl>
                        <SelectTrigger placeholder="Signer">
                          <SelectValue />
                        </SelectTrigger>
                      </FormControl>
                      <SelectContent>
                        <SelectItem value="true">Yes</SelectItem>
                        <SelectItem value="false">No</SelectItem>
                      </SelectContent>
                    </Select>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="ownership_percentage"
                render={({ field }) => (
                  <FormItem className="col-span-3">
                    <FormControl>
                      <Input
                        type="number"
                        placeholder="Ownership Percentage"
                        {...field}
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
            </div>
            <FormField
              control={form.control}
              name="address_search"
              render={({ field }) => {
                return (
                  <FormItem className="col-span-6">
                    <FormControl>
                      <AddressInput
                        googlePlacesApiKey={
                          import.meta.env.VITE_APPLY_GOOGLE_PLACES_API_KEY
                        }
                        placeholder="Home Address"
                        onSuggestionSelect={handleAddressSuggestionSelect}
                        {...field}
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                );
              }}
            />

            <FormField
              control={form.control}
              name="date_of_birth"
              render={({ field }) => {
                return (
                  <FormItem>
                    <FormControl>
                      <Input
                        placeholder="Date of Birth"
                        valueModifier={maskDate}
                        {...field}
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                );
              }}
            />

            {/* Phone number */}
            <div className="grid grid-cols-6 gap-4">
              <FormField
                control={form.control}
                name="phone"
                render={({ field }) => {
                  return (
                    <FormItem className="col-span-6">
                      <FormControl>
                        <Input
                          placeholder="Phone Number"
                          valueModifier={maskPhone}
                          {...field}
                        />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  );
                }}
              />
            </div>

            {/* Email */}
            <div className="grid grid-cols-6 gap-4">
              <FormField
                control={form.control}
                name="email"
                render={({ field }) => {
                  return (
                    <FormItem className="col-span-6">
                      <FormControl>
                        <Input
                          type="email"
                          placeholder="Personal Email"
                          {...field}
                        />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  );
                }}
              />
            </div>

            <FormField
              control={form.control}
              name="ssn"
              render={({ field }) => {
                return (
                  <FormItem>
                    <FormControl>
                      <Input
                        placeholder="Social Security Number"
                        type="password"
                        valueModifier={maskSSN}
                        {...field}
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                );
              }}
            />

            <div className="grid grid-cols-6 gap-4">
              {/* Business Role */}
              <FormField
                control={form.control}
                name="role"
                render={({ field }) => (
                  <FormItem className="col-span-3">
                    <Select
                      onValueChange={field.onChange}
                      defaultValue={field.value}
                    >
                      <FormControl>
                        <SelectTrigger placeholder="Business Role">
                          <SelectValue />
                        </SelectTrigger>
                      </FormControl>
                      <SelectContent>
                        <SelectItem value="ceo">CEO</SelectItem>
                        <SelectItem value="manager">Manager</SelectItem>
                        <SelectItem value="accountant">Accountant</SelectItem>
                        <SelectItem value="vice_president">
                          Vice President
                        </SelectItem>
                      </SelectContent>
                    </Select>
                    <FormMessage />
                  </FormItem>
                )}
              />

              {/* Employment Type */}
              <FormField
                control={form.control}
                name="type"
                render={({ field }) => (
                  <FormItem className="col-span-3">
                    <Select
                      onValueChange={field.onChange}
                      defaultValue={field.value}
                    >
                      <FormControl>
                        <SelectTrigger placeholder="Employment Type">
                          <SelectValue />
                        </SelectTrigger>
                      </FormControl>
                      <SelectContent>
                        <SelectItem value="owner">Owner</SelectItem>
                        <SelectItem value="employee">Employee</SelectItem>
                      </SelectContent>
                    </Select>
                    <FormMessage />
                  </FormItem>
                )}
              />
            </div>

            <div className="flex flex-col gap-2">
              <Button type="submit">Save Changes</Button>

              <Button
                variant="destructive"
                type="button"
                onClick={() => {
                  handleDeleteBeneficialOwner();
                  setOpen(false);
                }}
              >
                Delete Owner
              </Button>
            </div>
          </form>
        </Form>
      </DialogContent>
    </Dialog>
  );
}

export {
  AddBeneficialOwnerTrigger,
  UpdateBeneficialOwnerTrigger,
  type BeneficialOwner,
};

function setAddressFormFields(
  address: google.maps.GeocoderAddressComponent[],
  suggestion: google.maps.places.AutocompletePrediction,
  form: UseFormReturn<BeneficialOwner>
) {
  const convertedAddress = convertPlaceAddressComponentsToAddress(address);

  // Set the implicit google address values
  form.setValue(
    "_google_address_line_1",
    convertedAddress._google_address_line_1
  );
  form.setValue(
    "_google_address_line_2",
    convertedAddress._google_address_line_2
  );
  form.setValue("_google_address_city", convertedAddress._google_address_city);
  form.setValue(
    "_google_address_state",
    convertedAddress._google_address_state
  );
  form.setValue(
    "_google_address_postal_code",
    convertedAddress._google_address_postal_code
  );
  form.setValue(
    "_google_address_country_code",
    convertedAddress._google_address_country_code
  );

  // Set the address search input value
  form.setValue("address_search", suggestion.description, {
    shouldValidate: true,
  });
}
