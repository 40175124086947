import { useAuth0 } from "@auth0/auth0-react";
import { ChevronDown, MenuIcon } from "lucide-react";

import PrimeHeaderLogoSvg from "@/routes/-components/prime-header-logo.svg";
import { Button } from "@prime/ui/src/button";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "@prime/ui/src/dropdown-menu";

export function GlobalNavigation() {
  const auth = useAuth0();

  return (
    <div className="flex w-full items-center justify-between border-b border-slate-200 p-6 lg:px-16">
      <PrimeHeaderLogoSvg className="h-9 sm:h-10 lg:h-12" />
      <div className="hidden flex-row items-center justify-start sm:flex">
        <div className="flex flex-row items-center justify-center overflow-hidden px-4 py-1.5">
          <a
            href="mailto:support@primeft.com"
            className="text-action-primary leading-[150%]"
          >
            Support
          </a>
        </div>
        <DropdownMenu>
          <DropdownMenuTrigger className="text-action-primary border-border-dark box-border flex h-[60px] max-w-[480px] flex-row items-center justify-center gap-2 overflow-hidden rounded-md border-[1px] border-solid px-4 py-3">
            {auth.user?.email}
            <ChevronDown className="text-action-primary h-5 w-5 shrink-0 overflow-hidden" />
          </DropdownMenuTrigger>
          <DropdownMenuContent>
            <DropdownMenuLabel>My Account</DropdownMenuLabel>
            <DropdownMenuSeparator />
            <DropdownMenuItem onClick={() => auth.logout()}>
              Logout
            </DropdownMenuItem>
          </DropdownMenuContent>
        </DropdownMenu>
      </div>
      <Button variant="icon" size="icon-sm" className="sm:hidden">
        <MenuIcon />
      </Button>
    </div>
  );
}
