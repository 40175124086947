import { Link, createFileRoute, redirect } from "@tanstack/react-router";
import { useState } from "react";
import { useForm } from "react-hook-form";

import * as z from "zod";
import { postMagicLinkSignIn, getApplications } from "@/api";
import PrimeLogoSvg from "@/routes/-components/prime-logo.svg";
import { getRouteByApplicationStage } from "@/utils";
import { zodResolver } from "@hookform/resolvers/zod";
import { Button } from "@prime/ui/src/button";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormMessage,
} from "@prime/ui/src/form";
import { Input } from "@prime/ui/src/input";
import { AxiosError } from "axios";

const ERROR_OPTIONS = [
  "login_failed",
  "missing_offer",
  "authentication_required",
] as const;

const homepageSearchSchema = z.object({
  error: z.enum(ERROR_OPTIONS).optional(),
});

export const Route = createFileRoute("/")({
  validateSearch: homepageSearchSchema,
  beforeLoad: async ({ context, search }) => {
    const { auth, apiClient } = context;

    // ?error=authentication_required
    if (search.error === "authentication_required") {
      context.toast({
        title: "Authenticated required",
        description: "Please log in to access this route.",
        variant: "destructive",
      });
    }

    // ?error=login_failed
    if (search.error === "login_failed") {
      context.toast({
        title: "Login failed",
        description: "We were unable to log you in. Please try again.",
        variant: "destructive",
      });
    }

    // ?error=missing_offer
    if (search.error === "missing_offer") {
      context.toast({
        title: "Offer does not exist",
        description:
          "We were unable to find the offer you were looking for. Please try again.",
        variant: "destructive",
      });
    }

    const { error, isAuthenticated, user } = auth;

    if (!error && isAuthenticated && user) {
      if (!user.email)
        throw new Error(
          "Attempting to login authenticated user, but no email registered to user"
        );

      try {
        const { applications = [] } = await getApplications({
          apiClient,
        });

        const { to: redirectTo, params: redirectParams } =
          getRouteByApplicationStage(applications?.[0]);

        throw redirect({
          to: redirectTo,
          params: redirectParams,
          replace: true,
        });
      } catch (error) {
        if (error instanceof AxiosError) {
          console.error(error);
        } else {
          // This is a purposeful redirect "throw" and we don't want to catch it
          throw error;
        }
      }
    }
  },
  loader: async () => ({}),
  component: Page,
});

function Page() {
  const { unauthenticatedApiClient, toast } = Route.useRouteContext();
  const [processing, setProcessing] = useState<boolean>(false);
  const [emailSent, setEmailSent] = useState<string | null>(null);

  const formSchema = z.object({
    email: z.string().email({ message: "Please enter a valid email address" }),
  });

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      email: "",
    },
  });

  const redirectUri = `${window.location.origin}/authorize`;

  function onSubmit(values: z.infer<typeof formSchema>) {
    const email = values.email;

    setProcessing(true);

    postMagicLinkSignIn({
      apiClient: unauthenticatedApiClient,
      payload: { email, redirect_uri: redirectUri },
    })
      .then(() => {
        setEmailSent(email);
      })
      .catch(() => {
        toast({
          title: "Login failed",
          description: "We were unable to log you in. Please try again.",
          variant: "destructive",
        });
      })
      .finally(() => {
        setProcessing(false);
      });
  }

  if (emailSent) {
    return (
      <SharedLayout>
        <div>
          <h3 className="text-text-900 mb-2 text-2xl font-medium">
            Check your email
          </h3>
          <p className="text-text-readonly-secondary text-base font-light">
            We sent an email containing a link to complete your verification to{" "}
            <span className="font-bold">{emailSent}</span>.
          </p>
          <div className="mt-10 flex items-center justify-center">
            <Button
              size="lg"
              variant="ghost"
              className="text-primary"
              onClick={() => setEmailSent(null)}
            >
              Return to login
            </Button>
          </div>
        </div>
      </SharedLayout>
    );
  }

  const buttonDisabled = processing;

  return (
    <SharedLayout>
      <PrimeLogoSvg />
      <div className="max-w-xs text-center">
        <h1 className="text-text-readonly text-2xl font-extralight leading-tight">
          Finance your purchase in weekly installments.
        </h1>
      </div>

      <div className="mt-4 w-full">
        <div className="flex flex-col gap-8">
          <div>
            <p className="text-xl font-medium">Sign in</p>
            <p className="text-text-readonly-secondary text-sm">
              Sign in to view your existing loan details
            </p>
          </div>

          <Form {...form}>
            <form
              onSubmit={form.handleSubmit(onSubmit)}
              className="flex w-full flex-col gap-8"
            >
              <FormField
                control={form.control}
                name="email"
                render={({ field }) => (
                  <FormItem>
                    <FormControl>
                      <Input
                        type="email"
                        placeholder="Enter Email"
                        {...field}
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <Button
                type="submit"
                className="w-full"
                disabled={buttonDisabled}
              >
                Sign In
              </Button>
            </form>
          </Form>
        </div>
      </div>
    </SharedLayout>
  );
}

function SharedLayout({ children }: { children: React.ReactNode }) {
  return (
    <div className="bg-background-sidebar grid min-h-screen grid-cols-1 grid-rows-1 items-center justify-center">
      <div className="m-auto flex max-w-xl flex-col items-center gap-4 rounded-xl bg-white p-6">
        {children}
      </div>
      <footer className="flex w-full items-center justify-between bg-white/10 p-6 text-white">
        <p className="text-sm font-extralight">
          Prime Financial Technologies Inc.
        </p>
        <ul className="flex gap-4 text-sm font-extralight">
          <li>
            <a href="mailto:support@primeft.com">Contact Support</a>
          </li>
          <li>
            <Link to="/docs/privacy-policy">Privacy Policy</Link>
          </li>
          <li>
            <Link to="/docs/terms-of-service">Terms of Service</Link>
          </li>
          <li>
            <Link to="/docs/e-sign">E-Sign</Link>
          </li>
        </ul>
      </footer>
    </div>
  );
}
