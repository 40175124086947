import {
  ArrowLeftRightIcon,
  PieChartIcon,
  ReceiptIcon,
  CreditCardIcon,
  ClockIcon,
} from "lucide-react";

import PrimeHeaderLogoSvg from "@/routes/-components/prime-header-logo.svg";

import ScrollingPromotionCards, { PromotionCard } from "./ScrollPromotionCards";
import XeroSvg from "./xero.svg";

function XeroContainer({
  progress,
  children,
}: {
  progress: string | number;
  children: React.ReactNode;
}) {
  return (
    <div className="flex min-h-screen flex-1 flex-col items-start justify-start self-stretch overflow-hidden rounded-xl shadow-[0px_12px_48px_rgba(0,_0,_0,_0.25)]">
      <div className="bg-txt-inverted border-border-light box-border flex h-20 flex-row items-center justify-between self-stretch border-b-[1px] border-solid px-6 py-0">
        <div className="flex flex-row items-center justify-start gap-1 sm:gap-2">
          <PrimeHeaderLogoSvg className="h-8 sm:h-9" />
          <ArrowLeftRightIcon className="text-icon h-5 sm:h-6" />
          <XeroSvg className="h-4 sm:h-5" />
        </div>
        <div className="bg-surface-dark mx-6 flex h-2 max-w-[360px] flex-1 shrink-0 flex-col items-start justify-start overflow-hidden rounded-full">
          <div
            className={`bg-surface-banner h-2 transition-all duration-500 ease-in-out`}
            style={{ width: `${progress}%` }}
          />
        </div>
        <div className="flex flex-row items-center justify-end gap-[24px]">
          <a href="#" className="text-action-primary leading-[150%]">
            How it Works
          </a>
          <a
            href="mailto:support@primeft.com"
            className="text-action-primary leading-[150%]"
          >
            Support
          </a>
        </div>
      </div>
      {children}

      <ScrollingPromotionCards>
        <PromotionCard
          Icon={PieChartIcon}
          iconColor="text-[#3364F1]"
          title="Transparent Payments"
          description="We don’t collect a percentage of your sales. No more guessing how much you’ll be paying."
        />
        <PromotionCard
          Icon={ReceiptIcon}
          iconColor="text-[#C033F1]"
          title="Fixed, Up-Front, Pricing"
          description="We don’t collect a percentage of your sales. No more guessing how much you’ll be paying."
        />
        <PromotionCard
          Icon={CreditCardIcon}
          iconColor="text-[#EF7459]"
          title="No Impact on Credit"
          description="We don’t collect a percentage of your sales. No more guessing how much you’ll be paying."
        />
        <PromotionCard
          Icon={ClockIcon}
          iconColor="text-[#63E200]"
          title="Stress Free"
          description="We don’t collect a percentage of your sales. No more guessing how much you’ll be paying."
        />
      </ScrollingPromotionCards>
    </div>
  );
}

export default XeroContainer;
