import * as Sentry from "@sentry/react";
import { createFileRoute, useRouter } from "@tanstack/react-router";
import {
  CalendarCheck2Icon,
  CalendarClock,
  DollarSignIcon,
  Loader,
  Loader2,
} from "lucide-react";
import { useEffect, useState } from "react";
import ConfettiExplosion from "react-confetti-explosion";
import { usePlaidLink } from "react-plaid-link";

import {
  getAgreements,
  getApplication,
  getBankAccount,
  getBorrowerBusiness,
  getBusinessPersons,
  getLoan,
  getReconnectOpenBankingWidget,
  getTransactions,
} from "@/api";
import PrimeLogoSvg from "@/routes/-components/prime-logo.svg";
import { OpenBankingReconnectWidgetResponse } from "@/types";
import { Button } from "@prime/ui/src/button";

export const Route = createFileRoute("/_dashboard/dashboard/$applicationId/")({
  pendingComponent: () => (
    <div className="grid h-screen w-full items-center justify-center">
      <Loader className="text-primary h-12 w-12 animate-spin" />
    </div>
  ),
  loader: async ({ context, params }) => {
    const apiClient = context.apiClient;

    const application = await getApplication({
      apiClient,
      params: { id: params.applicationId },
    });

    const loanId = application.loan_id;
    const borrowerId = application.borrower_id;

    if (!loanId || !borrowerId)
      throw new Error(
        `Application: ${application.id} does not have a loan_id and/or a borrower_id`
      );

    const borrowerBusiness = await getBorrowerBusiness({
      apiClient,
      params: { borrower_id: borrowerId },
    });

    const businessPersons = await getBusinessPersons({
      apiClient,
      params: { borrower_id: borrowerId },
    });

    const agreements = await getAgreements({
      apiClient,
      params: { loanId },
    });

    const loan = await getLoan({
      apiClient,
      params: { loanId },
    });

    const transactions = await getTransactions({
      apiClient,
      params: { loanId },
    });

    const bankAccount = await getBankAccount({
      apiClient,
      params: { borrowerId },
    });

    return {
      agreements,
      application,
      bankAccount,
      borrowerBusiness,
      businessPersons,
      loan,
      transactions,
    };
  },
  component: Page,
});

function Page() {
  const router = useRouter();
  const { application, bankAccount, borrowerBusiness, businessPersons, loan } =
    Route.useLoaderData();
  const { apiClient } = Route.useRouteContext();
  const [openBankingWidgetData, setOpenBankingWidgetData] =
    useState<OpenBankingReconnectWidgetResponse | null>(null);

  const businessPersonName = businessPersons[0].first_name;
  const businessName = borrowerBusiness.name;

  const bankName = bankAccount[0].name;
  const bankAccountType = bankAccount[0].type;
  const bankAccountNumber = bankAccount[0].ach_account_number?.slice(-4);
  const bankAccountId = bankAccount[0].id;

  const [isReconnecting, setIsReconnecting] = useState(false);

  const handleClick = async () => {
    try {
      setIsReconnecting(true);
      const openBankingWidgetData = await getReconnectOpenBankingWidget({
        apiClient,
        payload: { bank_account_id: bankAccountId },
      });
      setOpenBankingWidgetData(openBankingWidgetData);
    } catch (error) {
      Sentry.captureException(error);
    }
  };

  const onSuccess = async () => {
    setIsReconnecting(false);
    router.invalidate();
  };

  const { open, ready } = usePlaidLink({
    token: openBankingWidgetData ? openBankingWidgetData.ob_widget_token : null,
    onSuccess,
    onExit: (): void => {
      setIsReconnecting(false);
    },
  });

  useEffect(() => {
    if (openBankingWidgetData && ready) {
      open();
    }
  }, [open, ready, openBankingWidgetData]);

  const USDollar = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  });

  const loanAmount = USDollar.format(parseFloat(loan.capital_amount));

  const interestPaid = USDollar.format(parseFloat(loan.terms.fee_amount));

  const weeklyPayments = Math.floor(
    loan.terms.loan_term_days / loan.terms.remittance_frequency
  );

  const paymentAmount = USDollar.format(
    parseFloat(loan.terms.remittance_payment)
  );

  const totalRepayAmount = USDollar.format(
    parseFloat(loan.terms.total_loan_amount)
  );

  const loanStatus =
    application.stage === "execute_loan_started" ? "In Progress" : "Completed";

  const [isExploding, setIsExploding] = useState(false);

  useEffect(() => {
    setIsExploding(true);
  }, []);

  return (
    <>
      {isExploding && (
        <ConfettiExplosion
          force={0.8}
          duration={3000}
          particleCount={250}
          width={1600}
          onComplete={() => setIsExploding(false)}
        />
      )}

      <div className="flex w-full items-center justify-between bg-slate-100 p-12">
        <PrimeLogoSvg
          onClick={() => setIsExploding(true)}
          className="h-8 w-auto"
        />
      </div>

      <div className="m-auto max-w-4xl py-12">
        {/* Welcome */}
        <div className="flex flex-col gap-2">
          <p className="text-readonly-secondary text-xl font-medium">
            Congratulations, {businessPersonName}!
          </p>
          <p className="text-5xl font-semibold">{businessName}</p>
        </div>

        {/* Loan status */}
        <div className="text-readonly-secondary mt-8 rounded border bg-slate-100 p-4">
          Status: <span className="font-medium capitalize">{loanStatus}</span>
        </div>

        {/* Loan details */}
        <div className="mt-6 flex flex-col gap-4">
          <div className="grid gap-4 md:grid-cols-2 lg:grid-cols-4">
            <Card
              title="Loan Amount"
              value={loanAmount}
              icon={<DollarSignIcon className="h-4 w-4" />}
              description="Deposited into your account"
            />

            <Card
              title="Total Interest Paid"
              value={interestPaid}
              icon={<DollarSignIcon className="h-4 w-4" />}
              description="Amount we charge you for your loan"
            />

            <Card
              title="Total You Repay"
              value={totalRepayAmount}
              icon={<DollarSignIcon className="h-4 w-4" />}
              description="Total amount needed to repay"
            />

            <Card
              title="Payment Amount"
              value={paymentAmount}
              icon={<DollarSignIcon className="h-4 w-4" />}
              description="Amount withdrawn each week"
            />
          </div>
          <div className="grid grid-cols-2 gap-4">
            <Card
              title="Time to Repay"
              value={`${String(loan.terms.loan_term_days)} days`}
              description="Total amount of time it wil take for your loan to be repaid"
              icon={<CalendarClock className="h-4 w-4" />}
            />

            <Card
              title="Weekly Payments"
              value={String(weeklyPayments)}
              description="Number of withdrawls we'll make from your bank."
              icon={<CalendarCheck2Icon className="h-4 w-4" />}
            />
          </div>
        </div>

        {/* Bank account info */}
        <div className="text-readonly-secondary mt-8 flex items-center justify-between rounded border bg-slate-100 p-4">
          <p className="text-sm font-medium tracking-wide">Connected Bank</p>
          <div className="flex flex-col">
            <p className="text-xl font-light">{bankName}</p>
            <p className="text-sm font-extralight">
              <span className="capitalize">{bankAccountType}</span>-####-
              {bankAccountNumber}
            </p>
          </div>
          <div className="flex flex-col">
            <p className="flex flex-col">
              <Button onClick={handleClick} disabled={isReconnecting}>
                Reconnect Bank
                {isReconnecting && (
                  <Loader2 className="ml-2 h-4 w-4 animate-spin" />
                )}
              </Button>
            </p>
          </div>
        </div>
      </div>
    </>
  );
}

function Card({
  title,
  icon,
  value,
  description,
}: {
  title: string;
  value: string;
  icon?: JSX.Element;
  description?: string;
}) {
  return (
    <div className="bg-card text-text-800 rounded-xl border shadow">
      <div className="flex flex-row items-center justify-between space-y-0 p-6 pb-2">
        <h3 className="text-sm font-medium tracking-tight">{title}</h3>
        {icon && icon}
      </div>
      <div className="p-6 pt-0">
        <div className="text-2xl font-semibold">{value}</div>
        {description && (
          <p className="text-muted-foreground text-xs">{description}</p>
        )}
      </div>
    </div>
  );
}
