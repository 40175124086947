export function AgreementSvg(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 200 200"
      {...props}
    >
      <rect
        width="1.326"
        height="38.465"
        x="58.026"
        y="128.52"
        fill="#000"
        rx=".663"
      />
      <rect
        width="1.326"
        height="23.875"
        x="76.596"
        y="155.047"
        fill="#000"
        rx=".663"
      />
      <rect
        width="1.326"
        height="46.424"
        x="173.423"
        y="136.479"
        fill="#000"
        rx=".663"
      />
      <rect
        width="1.326"
        height="21.222"
        x="189.34"
        y="151.068"
        fill="#000"
        rx=".663"
      />
      <path
        fill="#5C59EF"
        d="M147.487 146.797c2.519-5.278.348-11.516.254-11.78a.535.535 0 0 0-.542-.356.536.536 0 0 0-.485.431c-.006.028-.642 2.896-5.386 3.81-.746.143-1.306.306-1.817.526-1.007.436-2.776 1.38-4.054 3.006-1.287-.22-5.67-.457-9.047 5.467-.687-.109-2.09-.149-3.502.905-2.588 1.935-3.847 6.462-3.75 13.45-1.999 2.276-3.588 4.807-4.102 7.432a.541.541 0 0 0 .526.645c.25 0 .474-.178.524-.436.476-2.431 1.995-4.814 3.917-6.979.045.024.089.049.141.059 2.071.417 3.919.59 5.562.59 5.98 0 9.221-2.296 10.495-3.484.402-.374.637-.648.78-.854 2.302-.377 4.116-1.264 5.404-2.665 2.081-2.262 2.124-5.112 2.076-6.036a10.415 10.415 0 0 0 3.005-3.732l.001.001Z"
      />
      <path
        fill="#000"
        d="M146.141 145.451c2.519-5.277.348-11.516.254-11.779a.535.535 0 0 0-.541-.357.538.538 0 0 0-.486.431c-.006.029-.642 2.896-5.385 3.81-.746.144-1.307.306-1.818.527-1.007.435-2.776 1.379-4.054 3.006-1.286-.221-5.669-.457-9.047 5.466-.686-.109-2.09-.149-3.502.906-2.587 1.934-3.847 6.461-3.75 13.449-1.999 2.277-3.588 4.807-4.102 7.432a.54.54 0 0 0 .526.646c.25 0 .474-.179.525-.436.475-2.432 1.994-4.815 3.916-6.98.045.024.089.049.141.06 2.071.417 3.92.589 5.562.589 5.98 0 9.221-2.296 10.496-3.484.401-.374.636-.647.779-.854 2.303-.377 4.116-1.264 5.404-2.665 2.082-2.262 2.124-5.112 2.076-6.036a10.41 10.41 0 0 0 3.005-3.731h.001Zm-7.275 4.802a.543.543 0 0 0-.335.685.537.537 0 0 0 .679.339 14.02 14.02 0 0 0 2.851-1.345c-.055 1.112-.373 3.023-1.793 4.561-1.704 1.844-4.514 2.686-8.346 2.496a.543.543 0 0 0-.56.514.538.538 0 0 0 .508.566c.368.018.727.027 1.078.027.363 0 .71-.017 1.055-.036-1.677 1.489-6.028 4.169-14.482 2.603 4.716-4.889 11.139-8.456 11.252-8.519 7.221-4.479 11.201-7.945 11.512-10.024.085-.571-.132-.872-.2-.951a.532.532 0 0 0-.755-.049.545.545 0 0 0-.049.763c.02.023-.05.045-.054.075-.094.629-1.165 3.156-10.989 9.25-.396.217-6.624 3.672-11.36 8.544.065-7.363 1.802-10.273 3.298-11.406.893-.676 1.771-.785 2.343-.762a23.03 23.03 0 0 0-.902 2.087.542.542 0 0 0 .5.733.536.536 0 0 0 .5-.349c2.882-7.667 7.141-8.13 8.829-7.977-.566 1.024-.919 2.259-.857 3.734a.542.542 0 0 0 .556.517.538.538 0 0 0 .512-.563c-.063-1.52.386-2.744 1.067-3.723l.02-.029c1.064-1.513 2.684-2.437 3.841-2.937.436-.188.929-.33 1.597-.458 3.245-.625 4.826-2.109 5.58-3.236.482 2.059 1.075 6.119-.586 9.599-1.177 2.467-3.3 4.24-6.311 5.271h.001Z"
      />
      <path
        fill="#5C59EF"
        d="m133.395 27.063 4.755 1.79 10.22 3.846 3.041 1.145a1.505 1.505 0 0 0 1.936-.877l1.789-4.755a1.504 1.504 0 0 0-.877-1.936l-4.755-1.79-8.505-3.202-4.755-1.79a1.504 1.504 0 0 0-1.936.878l-1.791 4.755a1.505 1.505 0 0 0 .878 1.936Z"
      />
      <path
        stroke="#000"
        strokeWidth=".7"
        d="M133.625 18.63a1.155 1.155 0 0 1 1.486-.673l4.755 1.79 8.505 3.201 4.755 1.79c.595.224.897.89.673 1.485l-1.79 4.755c-.224.595-.89.897-1.485.673l-3.04-1.145-10.221-3.846-4.755-1.79a1.154 1.154 0 0 1-.673-1.485l1.79-4.756Z"
      />
      <path
        fill="#5C59EF"
        d="m165.686 63.502 1.894.508 1.052-3.929a8.76 8.76 0 0 1 2.202-3.843 8.34 8.34 0 0 1 3.753-2.226 8.533 8.533 0 0 1 8.308 2.226 8.455 8.455 0 0 1 2.187 3.851 8.277 8.277 0 0 1-.065 4.382l-1.051 3.93 1.896.507c.576.155 1.039.53 1.318 1.01.281.477.378 1.062.224 1.635l-3.653 13.634a2.139 2.139 0 0 1-1.011 1.305l-.039.02a2.176 2.176 0 0 1-1.608.194l-20.175-5.407a2.17 2.17 0 0 1-1.318-1.009 2.127 2.127 0 0 1-.224-1.635l3.653-13.633a2.138 2.138 0 0 1 1.01-1.306l.04-.02a2.174 2.174 0 0 1 1.607-.194Zm5.624 9.5a2.51 2.51 0 0 1 1.185-1.536 2.558 2.558 0 0 1 1.936-.249 2.556 2.556 0 0 1 1.552 1.184 2.511 2.511 0 0 1-1.177 3.581l-.507 1.895c-.113.42-.389.757-.742.959a1.599 1.599 0 0 1-2.341-1.785l.508-1.895a2.539 2.539 0 0 1-.414-2.155Zm1.709-.638a1.488 1.488 0 0 0-.357 2.302c.13.127.19.318.14.505l-.577 2.15a.552.552 0 0 0 1.065.285l.576-2.15a.52.52 0 0 1 .352-.36c.246-.074.471-.21.648-.391a1.487 1.487 0 0 0 .215-1.788 1.516 1.516 0 0 0-2.062-.552Z"
      />
      <path
        fill="#FCFBFC"
        d="M169.937 70.625a2.511 2.511 0 0 1 1.185-1.536 2.559 2.559 0 0 1 1.936-.248 2.56 2.56 0 0 1 1.553 1.183 2.515 2.515 0 0 1-1.178 3.582l-.507 1.894c-.113.42-.388.757-.741.959a1.6 1.6 0 0 1-2.179-.584 1.574 1.574 0 0 1-.163-1.2l.508-1.895a2.558 2.558 0 0 1-.421-.888 2.498 2.498 0 0 1 .007-1.267Z"
      />
      <path
        fill="#000"
        d="m164.313 61.127 1.894.508 1.052-3.929a8.76 8.76 0 0 1 2.202-3.843 8.34 8.34 0 0 1 3.753-2.226 8.533 8.533 0 0 1 8.308 2.226 8.455 8.455 0 0 1 2.187 3.851 8.277 8.277 0 0 1-.065 4.382l-1.051 3.93 1.896.507c.576.155 1.039.53 1.318 1.01.281.477.378 1.062.224 1.635l-3.653 13.634a2.139 2.139 0 0 1-1.011 1.305l-.039.02a2.176 2.176 0 0 1-1.608.194l-20.175-5.407a2.175 2.175 0 0 1-1.319-1.009 2.132 2.132 0 0 1-.223-1.635l3.653-13.633a2.138 2.138 0 0 1 1.01-1.306l.04-.02a2.174 2.174 0 0 1 1.607-.194Zm5.623 9.5a2.516 2.516 0 0 1 1.186-1.536 2.558 2.558 0 0 1 1.936-.249 2.555 2.555 0 0 1 1.552 1.184 2.511 2.511 0 0 1-1.177 3.581l-.507 1.895c-.113.42-.389.757-.742.959a1.599 1.599 0 0 1-2.178-.584 1.569 1.569 0 0 1-.163-1.2l.508-1.896a2.539 2.539 0 0 1-.415-2.155Zm1.71-.638a1.488 1.488 0 0 0-.357 2.302c.13.127.19.318.14.505l-.577 2.15a.552.552 0 0 0 1.065.285l.576-2.15a.52.52 0 0 1 .352-.36c.246-.074.471-.21.648-.391a1.487 1.487 0 0 0 .215-1.788 1.515 1.515 0 0 0-2.062-.552Zm-4.431-8.084 1.814.486 1.052-3.928a5.899 5.899 0 0 1 1.455-2.552 5.445 5.445 0 0 1 2.463-1.48 5.6 5.6 0 0 1 5.446 1.46 5.54 5.54 0 0 1 1.434 2.53c.238.939.23 1.94-.052 2.897l-1.059 3.95 1.815.487 1.058-3.94a7.252 7.252 0 0 0 .059-3.852 7.408 7.408 0 0 0-1.917-3.377 7.486 7.486 0 0 0-3.382-1.959 7.492 7.492 0 0 0-3.909.005 7.295 7.295 0 0 0-3.282 1.951 7.73 7.73 0 0 0-1.942 3.394l-1.053 3.929Zm2.822.757L178.763 65l1.065-3.975a4.49 4.49 0 0 0-1.122-4.412 4.548 4.548 0 0 0-2.054-1.189 4.554 4.554 0 0 0-2.375.002 4.397 4.397 0 0 0-1.987 1.201 4.853 4.853 0 0 0-1.2 2.106l-1.053 3.929Zm14.184 4.872-20.176-5.406a1.132 1.132 0 0 0-.828.095l-.027.017c-.25.143-.446.38-.526.677l-3.653 13.634a1.128 1.128 0 0 0 .801 1.374l20.176 5.406c.29.078.585.036.828-.095l.027-.017c.25-.143.446-.38.526-.677l3.653-13.634a1.128 1.128 0 0 0-.801-1.374Z"
      />
      <path
        fill="#D0DAF7"
        d="m144.791 94.33 5.361-2.043c.591-.226 1.271.122 1.521.778l3.956 10.375c2.502 6.564-.264 13.735-6.168 15.986l-47.427 18.081a.284.284 0 0 1-.038.013l-.87.291c-5.827 2.174-12.61-1.334-15.088-7.832l-23.92-62.743-14.864 5.667c-.59.226-1.271-.123-1.521-.778l-3.346-8.777c-2.156-5.654.227-11.831 5.312-13.77l.143-.054h.001l59.141-22.508c5.866-2.187 12.648 1.321 15.125 7.82l22.682 59.494Z"
      />
      <path
        stroke="#000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        d="m90.114 85.496 6.929 4.744 13.351-20.548"
      />
      <path
        fill="#000"
        stroke="#fff"
        strokeWidth=".4"
        d="m139.557 94.517 4.743-1.808.617-.235c.471-.18 1.046.091 1.264.662l3.955 10.375c2.47 6.48-.27 13.523-6.051 15.728L96.657 137.32l-.013.005h-.002l-.006.002-.01.003-.869.292-.006.002c-5.706 2.129-12.386-1.301-14.831-7.716L57 67.165l-.072-.187-.187.072-14.863 5.666c-.47.18-1.046-.092-1.264-.662l-3.346-8.777c-2.123-5.57.233-11.62 5.197-13.512l.142-.054h.002v-.001l59.139-22.507h.001c5.745-2.141 12.422 1.29 14.868 7.704l22.681 59.495.072.186.187-.07Zm-97.63-24.32.071.186.187-.071 13.795-5.26.187-.072-.071-.187-3.122-8.187c-1.563-4.102-5.86-6.324-9.603-4.898l-.145.055c-4.026 1.535-5.882 6.411-4.193 10.842l2.894 7.591Zm53.842 65.169.004.005.129-.05c4.843-1.85 7.08-7.716 5.044-13.055l-3.955-10.375c-.218-.571.032-1.157.502-1.336l39.926-15.221.187-.071-.072-.187-6.509-17.074-.071-.187-.187.072-7.556 2.88c-.471.18-1.046-.092-1.264-.662-.218-.572.032-1.157.502-1.336l7.556-2.88.187-.072-.071-.187-15.268-40.049c-2.014-5.283-7.542-8.168-12.348-6.376h-.001L49.072 49.54l-.464.177.457.194c2.463 1.05 4.586 3.168 5.675 6.024l27.945 73.302c2.013 5.281 7.54 8.167 12.346 6.376l.003-.001.735-.246Zm6.288-2.77-.27.482.516-.197 41.022-15.64c4.846-1.847 7.085-7.716 5.049-13.057l-3.504-9.19-.071-.186-.187.071-45.288 17.267-.187.071.07.187 3.504 9.189c1.464 3.841 1.097 7.888-.654 11.003Z"
      />
      <path
        fill="#FCFBFC"
        d="m89.13 56.199 8.48-3.233c.591-.226.868-.938.618-1.594-.25-.655-.93-1.004-1.522-.778l-8.48 3.233c-.59.226-.867.938-.617 1.594.25.655.93 1.003 1.521.778ZM80.4 59.535l3.314-1.264c.591-.225.868-.938.618-1.593s-.93-1.004-1.522-.779l-3.314 1.264c-.59.225-.867.938-.617 1.593.25.656.93 1.004 1.521.779ZM84.092 66.376c-.25-.655-.93-1.004-1.521-.778l-8.48 3.233c-.59.225-.867.937-.617 1.593.25.655.93 1.004 1.521.779l8.48-3.233c.59-.225.867-.938.617-1.594ZM94.744 62.657c-.25-.655-.78-1.062-1.185-.907l-5.805 2.213c-.405.154-.53.81-.28 1.465s.78 1.062 1.184.907l5.806-2.213c.404-.154.53-.81.28-1.465ZM114.551 84.29l3.312-1.262c.591-.226.868-.938.618-1.594-.25-.655-.93-1.004-1.522-.778l-3.312 1.263c-.591.225-.867.938-.617 1.594.249.655.929 1.003 1.521.778ZM116.723 90.353l-8.481 3.233c-.59.226-.867.938-.617 1.594.25.655.93 1.004 1.522.778l8.48-3.233c.591-.225.867-.937.617-1.593-.25-.655-.931-1.004-1.521-.779ZM123.045 90.673l3.314-1.263c.591-.225.867-.938.617-1.594-.25-.655-.93-1.004-1.521-.778l-3.314 1.263c-.591.225-.868.938-.618 1.594.251.655.931 1.004 1.522.778Z"
      />
      <path
        fill="#5C59EF"
        d="m12.764 98.35 40.47-10.843a1.481 1.481 0 0 1 1.19.2c.364.236.634.615.752 1.054l2.218 8.28c.118.439.073.902-.125 1.288a1.481 1.481 0 0 1-.93.769L15.87 109.94a1.476 1.476 0 0 1-1.19-.201 1.808 1.808 0 0 1-.752-1.053l-2.219-8.279a1.81 1.81 0 0 1 .126-1.289c.197-.385.532-.662.93-.769Z"
      />
      <path
        stroke="#000"
        d="m9.894 97.833.483-.13L50.363 86.99a.981.981 0 0 1 .789.138c.255.164.454.437.541.762l2.218 8.28a1.31 1.31 0 0 1-.087.93.982.982 0 0 1-.614.515l-40.47 10.843a.978.978 0 0 1-.788-.138 1.306 1.306 0 0 1-.541-.762l-2.219-8.28a1.31 1.31 0 0 1 .087-.93.981.981 0 0 1 .615-.515Z"
      />
      <path
        fill="#FCFBFC"
        d="m24.937 97.642-8.7 2.331c-.555.149-.918.594-.81.994.107.4.643.604 1.198.455l8.7-2.331c.555-.149.917-.594.81-.994-.107-.4-.643-.604-1.198-.455ZM32.128 99.38l-14.725 3.945c-.554.148-.917.593-.81.993.108.4.644.604 1.199.456l14.724-3.946c.555-.148.917-.593.81-.993-.107-.4-.644-.604-1.198-.456Z"
      />
    </svg>
  );
}
